<template>
  <div
    v-loading.fullscreen.lock="fetchingDaata"
    element-loading-background="rgba(0, 0, 0, 0.4)"
    element-loading-text="Please wait"
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
  >
  <section class="vechile-list page-content">
    <head_mobile />
    <!-- <BaiSkeletonResultListing v-if="!isLoaded"/> -->
     <!-- v-if="isLoaded" -->
    <div class="container" >
      <div class="vechile-list-container main-container">
        <div class="vechile-list_row">
          <div class="we-found text-center">
            <div class="row justify-content-center">
              <div class="col-md-6">
                <p v-if="fetchingDaata && allPolicy && allPolicy.length === 0"> {{ $t('searching_for_car_insurance') }} </p>
                <p v-if="allPolicy && allPolicy.length > 0">
                  {{ $t('found') }} {{ allPolicy.length }} {{ $t("car_insurance_plan") }}
                  <span v-if="manufacturers"> {{ $t('for') }} {{ manufacturers ? manufacturers.label : "" }}
                  {{ models ? models.label : "" }}
                  {{ trimLevels ? trimLevels.label : "" }},
                  {{ vehicle ? vehicle.car_year : "" }} {{ $t("declared_value") }}
                  is {{ $filters.currency(vehicle ? vehicle.car_values : 0)}}</span>
                </p>
              </div>
            </div>
          </div>
          <template v-if="allPolicy && allPolicy.length > 0">
            <div class="page-title-block result-listing-title inner">
              <div class="row align-items-center gx-lg-5">
                <div class="col-lg-3 left-col">
                  <div class="page-title m-0">
                    <h2 class="mb-0"> {{ $t('compare_title') }} </h2>
                  </div>
                </div>
                <div class="col-lg-6 mid-col">
                  <div class="result-tabs-nav text-center">
                    <div
                      class="nav nav-tabs d-inline-flex border-0"
                      id="nav-tab"
                      role="tablist"
                    >
                      <button
                        class="compare-link active"
                        id="all-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#all"
                        type="button"
                        role="tab"
                        aria-controls="all"
                        aria-selected="true"
                      >
                        {{ $t('result_all') }}
                      </button>
                      <button
                        class="compare-link"
                        id="comprehensive-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#comprehensive"
                        type="button"
                        role="tab"
                        aria-controls="comprehensive"
                        aria-selected="false"
                      >
                        {{ $t('result_comprehensive') }}
                      </button>
                      <button
                        class="compare-link"
                        id="third-party-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#third-party"
                        type="button"
                        role="tab"
                        aria-controls="third-party"
                        aria-selected="false"
                      >
                        {{ $t('result_tp') }}
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 right-col">
                  <div class="title-desc">
                    <p> {{ $t('price_vat') }} </p>
                  </div>
                </div>
              </div>
            </div>
            

            <div class="tab-content" id="nav-tabContent" >
              <div
                class="tab-pane fade show active"
                id="all"
                role="tabpanel"
                aria-labelledby="all-tab"
              >
                <div class="result-listing">
                  <div class="result-listing-full-block">
                    <!-- <el-checkbox-group v-model="checkCompare" :max="3">  -->
                    <template v-for="(policy, index) in allPolicy" :key="index">
                      <ResultListing
                        :detail="policy"
                        :limit="3"
                        :checkCompare="checkCompare"
                        :numeric="index + 1000"
                        @checkToCompare="addToChcek"
                      />
                    </template>
                    <!-- </el-checkbox-group> -->
                    <!-- end -->
                  </div>
                  

                </div>
              </div>
              <template v-if="hasData">
                <div
                  class="tab-pane fade"
                  id="comprehensive"
                  role="tabpanel"
                  aria-labelledby="comprehensive-tab"
                >
                  <div class="result-listing-full-block">
                    <template v-if="comprehensive.length > 0">
                      <ResultListing
                        v-for="(policy, index) in comprehensive" 
                        :key="index"
                        :detail="policy"
                        :limit="3"
                        :checkCompare="checkCompare"
                        :numeric="index + 2000"
                        @checkToCompare="addToChcek"
                      />
                    </template>
                    <p class="text-center" v-else> {{ $t('no_plans_available') }} </p>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="third-party"
                  role="tabpanel"
                  aria-labelledby="third-party-tab"
                >
                  <div class="result-listing-full-block">
                    <template v-if="thirdParty.length > 0">
                      <ResultListing
                        v-for="(policy, index) in thirdParty"
                        :key="index"
                        :detail="policy"
                        :limit="3"
                        :checkCompare="checkCompare"
                        :numeric="index + 3000"
                        @checkToCompare="addToChcek"
                      />
                    </template>
                    <p class="text-center" v-else> {{ $t('no_plans_available') }} </p>
                  </div>
                </div>
              </template>
              <template v-if="!hasData">
                <p class="text-center"> {{ $t('no_data_found') }} </p>
              </template>
            </div>
            <el-table
              v-if="loading"
              v-loading="loading"
              element-loading-text="Loading..."
              :data="tableData"
              style="width: 100%"
            >
            </el-table>
          </template>
          <NoQuotesFound v-if="!fetchingDaata && comprehensive.length === 0 && thirdParty.length === 0"/>
          
        </div>
      </div>
    </div>
    <CompareList :data="checkCompare" :isOpen="checkCompare.length > 1" />
  </section>
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from "vue";
import Qoutes from "@/core/services/car/Qoutes";
import Title from "@/layouts/reusable/Title.vue";
import QoutesBoxDetails from "@/components/Qoutes.vue";
import { store } from "@/store";
import CompareList from "@/layouts/reusable/CompareList.vue";
import Stepper from "@/core/services/etc/Steppers";
import ResultListing from "@/layouts/reusable/new/ResultItem.vue";
import router from "@/router";
import BaiSkeletonResultListing from "@/layouts/skeleton/resultListing.vue";
import NoQuotesFound from '@/components/new/NoQuotesFound.vue';
import head_mobile from '@/layouts/head_mobile.vue';

export default defineComponent({
  components: {
    QoutesBoxDetails,
    Title,
    ResultListing,
    CompareList,
    BaiSkeletonResultListing,
    NoQuotesFound,
    head_mobile
  },

  setup() {
    const isLoaded = ref(false);

    const current = computed(() => {
      return store.state.steppers.stepper;
    });
    const fetchingDaata = ref(true)
    onMounted(async() => {
      setTimeout(() => {
        isLoaded.value = true;
      }, 500);

      const value = {
        language: language.value
      }

      const driver_id = router.currentRoute.value.query.driver_id;
      const vehicle_type = router.currentRoute.value.query.vehicle_type;
      if(vehicle_type && vehicle_type == 2) {
        const path = process.env.VUE_APP_BIKE_LINK
        window.location.href = `${path}bike-result-listing?driver_id=${driver_id}&vehicle_type=${vehicle_type}`
      }
        
      if(driver_id) value.driver_id = parseInt(driver_id)
      if(!driver_id) value.uuid = router.currentRoute.value.query.uuid
      
      let data= false
      if(value.uuid || value.driver_id) data = await Qoutes.getomprehensiveAndThirdParty(value); 
      

      if(data) {
        fetchingDaata.value = false
      }

      // Qoute.sendCheapestQuotes({
      //   uuid:router.currentRoute.value.query.uuid
      // });
      const routeValue = {
        vehicle: "done",
        driver: "done",
        compare: "active",
        cover: "",
      };
      Stepper.setVehicleDetails(routeValue);

      // const fallBackDD = window.localStorage.getItem("fall_back_dd");
      // if(!fallBackDD) {
        window.localStorage.setItem('fall_back_dd', router.currentRoute.value.query.uuid);
      // }

      // const myStorage = window.localStorage.getItem("customer_detatils");
      // if (myStorage) {
      //   const vehicle = JSON.parse(myStorage);
      //   setVehicle.setVehicle(vehicle);
      //   Models.getModelsByManufacturerId({
      //     manufacturer_id: vehicle.manufacturer_id,
      //     year: vehicle.car_year
      //   });
      //   TrimLevels.getTrimLevelsByYearAndModelId({
      //     model_id: vehicle.model_id,
      //     year: vehicle.car_year,
      //   });
      // }
      
    });

    const loading = computed(() => {
      return store.state.qoutes.isLoading;
    });

    const comprehensive = computed(() => {
      return store.state.qoutes.comprehensive;
    });

    const thirdParty = computed(() => {
      return store.state.qoutes.thirdParty;
    });

    const hasData = computed(() => {
      return store.state.qoutes.hasData
    })

    const manufacturers = computed(() => {
      const data = store.state.manufaturers.manufacturers;
      const myStorage = window.localStorage.getItem("customer_detatils");
      if(myStorage) {
        const vehicle = JSON.parse(myStorage);
        return data.find((x) => x.id == vehicle.manufacturer_id);
      }
      return '';
    });
    const vehicle = computed(() => {
      const myStorage = window.localStorage.getItem("customer_detatils");
      if(myStorage) {
        return JSON.parse(myStorage);
      }
      return '';
    });

    const models = computed(() => {
      const data = store.state.models.models;
      const myStorage = window.localStorage.getItem("customer_detatils");
      if(myStorage) {
        const vehicle = JSON.parse(myStorage);
        return data.find((x) => x.id == vehicle.model_id);
      }
      return '';
    });
    const trimLevels = computed(() => {
      const data = store.state.trimLevels.trimLevels;
      const myStorage = window.localStorage.getItem("customer_detatils");
      if(myStorage) {
        const vehicle = JSON.parse(myStorage);
        return data.find((x) => x.id == vehicle.trim_level_id);
      }
      return '';
    });

    // const total = computed(() => {
    //     return comprehensive.value + thirdParty.value
    // })

    const isCompareOpen = computed(() => {
      return store.state.qoutes.isCompareOpen;
    });

    const allPolicy = computed(() => {
      return comprehensive.value.concat(thirdParty.value).sort((a, b) => {
        return a.sale_price - b.sale_price;
      });
    });

    const checkCompare = ref([]);

    const addToChcek = (e) => {
      const index = checkCompare.value.findIndex((item) => item == e);
      // console.log(e);
      if (index == -1) {
        checkCompare.value.push(e);
      } else {
        checkCompare.value.splice(index, 1);
      }
    };

    const language = computed(() => {
      return window.localStorage.getItem("language");
    })

    const customer_detatils = JSON.parse(window.localStorage.getItem('customer_detatils'));

    const url = computed(() => {
      return customer_detatils.value && customer_detatils.value.customer_source_url ? customer_detatils.value.customer_source_url : '/vehicle-details';
    })

    return {
      fetchingDaata,
      url,
      customer_detatils,
      hasData,
      current,
      language,
      isLoaded,
      manufacturers,
      vehicle,
      models,
      trimLevels,
      comprehensive,
      thirdParty,
      allPolicy,
      isCompareOpen,
      checkCompare,
      addToChcek,
      loading,
    };
  },
});
</script>
<style>
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 14.5px !important;
  line-height: 20px !important;
}
</style>
